<template>
 <v-runtime-template :template="template"></v-runtime-template>
</template>
 
<script>
console.log("Footer");
import VRuntimeTemplate from "vue3-runtime-template";
import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
export default {
 name: "FooterTemplate",
 data() {
  var res = JSON.parse(localStorage.getItem("res"));
  if (res.site && res.site.footer_copyright)
   res.site.footer_copyright = res.site.footer_copyright
    .replace("©", "&copy;")
    .replace("&copy;", "&copy;" + new Date().getFullYear());

  return {
   v: this,
   w: window,
   h: axios,
   template: res.templates.footerTemplate.content,
   res: res,
   intervalId: null,
   ticket:null
  };
 },
 components: {
  VRuntimeTemplate,
 },
 watch: {
  // Watch for route changes
  $route() {
   $("#footer_route_reload").click();
  },
 },
 methods: {
  jQuery(selectort) {
   return $(selectort);
  },
  swalFire(obj, _then) {
   var v = this;
   if (obj.preConfirmFormID) {
    obj.preConfirm = this.preCheckForm(obj.preConfirmFormID);
   }
   Swal.fire(obj).then((result) => {
    if (obj.isConfirmed && result.isConfirmed) obj.isConfirmed(result);
    if (_then) _then(result, v, window);
   });
  },
  saveStorage(a, b) {
   this.$globalVariable[a] = b;
   localStorage.setItem(a, JSON.stringify(b));
   this.$globalVariable.ticket++;
  },
  getStorage(a) {
   if (!localStorage.getItem(a)) return this.$globalVariable[a];
   var b = localStorage.getItem(a);
   if (b) b = JSON.parse(b);
   return b;
  },
  preCheckForm(formID) {
   return new Promise((resolve, reject) => {
    setTimeout(() => {
     const inputs = document.querySelectorAll(
      "#" + formID + " input,#" + formID + " textarea,#" + formID + " select"
     );
     for (let input of inputs) {
      const pattern = input.getAttribute("pattern");
      if (pattern && !new RegExp(pattern).test(input.value)) {
       reject("驗證失败");
       return ;
      }
     }
     let result = {};
     inputs.forEach((input) => {
      result[input.name] = input.value;
     });
     resolve(result);
    }, 200);
   });
  },
  submitFormJson(formID, actionUrl, succ_call, error_call) {
   var formData = {};
   $("#" + formID)
    .find(":input[name]")
    .each(function () {
     formData[this.name] = $(this).val();
    });
   axios
    .post(actionUrl, "json=" + JSON.stringify(formData), {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    })
    .then((response) => {
     console.log(response);
     if (succ_call) succ_call();
    })
    .catch((error) => {
     console.error("Error fetching items:", error);
     if (error_call) error_call();
    });
  },
  reload() {
   this.res = this.getStorage('res');
   if(!this.res) this.res = {};
   $("#footer_load_click").click();
  },
 },
 mounted: function () {
  console.log("Footer  mounted");
  this.reload();
  this.intervalId = setInterval(() => {
   if(this.ticket != this.$globalVariable.ticket)
   {
    this.ticket = this.$globalVariable.ticket
    $("#footer_ticket_changed").click();
   }
  }, 250);
 },
};
</script>
