<template>
 <div :id="randomId" class="owl-carousel owl-theme index_banner">
  <v-runtime-template :template="itemTemplate"></v-runtime-template>
 </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import VRuntimeTemplate from "vue3-runtime-template";
import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
// <OwlCarousel apiEndpoint='/api/Banners/List' itemTemplate='<img :src="'/data/banners/' + a.image" alt="" />' settingString='{"loop":true,"margin":0,"autoHeight":true,"autoplay":true,"autoplayTimeout":4000,"autoplayHoverPause":true,"smartSpeed":1000,"responsive":{"0":{"items":1},"600":{"items":1},"800":{"items":1},"1000":{"items":1}}}'> </OwlCarousel>
export default {
 name: "OwlCarousel",
 components: {
  VRuntimeTemplate,
 },
 props: {
  apiEndpoint: {
   type: String,
   required: true,
  },
  itemTemplate: {
   type: String,
   required: true,
  },
  itemFire: {
   type: String,
   required: true,
  },
  settingString: {
   type: String,
   required: true,
  },
 },
 data() {
  const randomId = `id_${Math.random().toString(36).substr(2, 9)}`;

  return {
   randomId: randomId,
   items: {},
   settings: {},
  };
 },
 methods: {
  swalFire(obj, _then) {
   var v = this;
   if (obj.preConfirmFormID) {
    obj.preConfirm = this.preCheckForm(obj.preConfirmFormID);
   }
   Swal.fire(obj).then((result) => {
    if (obj.isConfirmed && result.isConfirmed) obj.isConfirmed(result);
    if (_then) _then(result, v, window);
   });
  },
  fetchItems() {
   axios
    .get(this.apiEndpoint)
    .then((response) => {
     this.items = response.data;
     this.initializeCarousel();
    })
    .catch((error) => {
     console.error("Error fetching items:", error);
    });
  },
  initializeCarousel() {
   var v = this;
   this.$nextTick(() => {
    setTimeout(() => {
     console.log("initializeCarousel");
     var obj = JSON.parse(this.settingString);
     $("#" + this.randomId).owlCarousel(obj);
     $("#" + this.randomId + ".owl-carousel").on(
      "click",
      ".owl-item>div",
      function () {
       if (v.itemFire == "Y") {
        v.swalFire({
         html: $(this).html(),
         type: "info",
         customClass: "swal-wide",
         width: "100%",
         animation: false,
         showCancelButton: false,
         showConfirmButton: false,
         showCloseButton: true
        });
       }
      }
     );
    }, 200);
   });
  },
 },
 mounted() {
  this.$nextTick(() => {
   if (this.apiEndpoint && this.apiEndpoint != "") this.fetchItems();
  });
 },
};
</script>
